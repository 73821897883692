<template>
    <div>
        <div class="grid content-section implementation">
            <div class="col-12">
                <div class="card">
                    <div class="text-800 mb-1">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="inventario-risco-list" />
                            {{ subtitle }}
                        </div>
                    </div>
                    <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                        <div class="p-fluid mt-2 md:mt-0 md:w-5">
                            <div style="display: flex; align-items: center; justify-content: space-between">
                                <InputText v-model="filter" style="width: 80%" placeholder="Pesquisar" @change="search($event)" />
                                <!-- <Button icon="pi pi-cog" @click="openBasic" /> -->
                            </div>
                            <Dialog header="Novo Cargo" v-model:visible="displayCharge" style="width: 45vw" :breakpoints="{ '960px': '90vw' }">
                                <div style="display: flex; margin-bottom: 20px">
                                    <div style="display: flex; flex-direction: column; margin-top: 10px">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label style="margin-right: 5px">Cargo</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                filter
                                                :selectionLimit="1"
                                                :options="listCharge"
                                                v-model="formCharge.charge"
                                                optionLabel="name"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !formCharge.charge[0]?.id }"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Hierarquia</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                filter
                                                :selectionLimit="1"
                                                :options="listHierarchy"
                                                v-model="formCharge.hierarchy"
                                                optionLabel="name"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !formCharge.hierarchy[0]?.id }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-5" style="display: flex; flex-wrap: wrap; justify-content: space-between">
                                    <Button label="Criar cargo" icon="pi pi-check" class="p-button-primary w-6" @click="createNewCharge" />
                                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="displayCharge = false" />
                                </div>
                            </Dialog>
                            <Dialog
                                header="Selecionar funcionário"
                                v-model:visible="displayEmployees"
                                style="width: 45vw"
                                :breakpoints="{ '960px': '90vw' }"
                            >
                                <div style="display: flex; margin-bottom: 20px">
                                    <div style="display: flex; flex-direction: column; margin-top: 10px">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Funcionários</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                filter
                                                :options="listEmployees"
                                                v-model="formCharge.employees"
                                                optionLabel="name"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !formCharge.employees?.length }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-5" style="display: flex; flex-wrap: wrap; justify-content: space-between">
                                    <Button label="Criar funcionários" icon="pi pi-check" class="p-button-primary w-6" @click="createNewEmployees" />
                                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="displayEmployees = false" />
                                </div>
                            </Dialog>
                            <Dialog header="Filtrar" v-model:visible="displayBasic" style="width: 45vw" :breakpoints="{ '960px': '90vw' }">
                                <div style="display: flex; margin-bottom: 20px">
                                    <div style="display: flex; flex-direction: column; margin-top: 10px">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label style="margin-right: 5px">Grupo de empresa</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.companyGroup"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.companyGroup }"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Empresa/Cliente</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.client"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.client }"
                                            />
                                        </div>

                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Unidade</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.unit"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.unit }"
                                            />
                                        </div>

                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Setor</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.sector"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.sector }"
                                            />
                                        </div>

                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Cargo</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.office"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.office }"
                                            />
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; margin-top: 10px; margin-left: 5%">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label style="margin-right: 5px">Funcionário</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.employee"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.employee }"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Exame</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.exam"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.exam }"
                                            />
                                        </div>

                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Risco</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.risk"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.risk }"
                                            />
                                        </div>

                                        <div
                                            style="
                                                display: flex;
                                                max-width: 250px;
                                                min-width: 250px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label style="margin-right: 5px">Adicionais</label>
                                            <MultiSelect
                                                style="max-width: 120px; min-width: 120px"
                                                v-model="form.additional"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                                :class="{ 'p-invalid': submitted && !form.additional }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-5" style="display: flex; flex-wrap: wrap; justify-content: space-between">
                                    <Button label="Salvar filtros" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                                    <Button label="Limpar filtros" icon="pi pi-times" class="p-button-text" @click="cleanFilters" />
                                </div>
                            </Dialog>
                        </div>
                        <div class="mt-2 ml-auto md:mt-0">
                            <Dropdown
                                style="max-width: 120px; min-width: 120px; margin-right: 10px"
                                @change="openModal($event)"
                                :options="optionsAction"
                                optionLabel="label"
                                placeholder="Ações"
                            />
                            <!-- <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="aplicarRisco"/> -->
                        </div>
                    </div>

                    <Toast />

                    <div class="p-fluid painel painel-2">
                        <TabView :scrollable="true" v-model:activeIndex="active">
                            <TabPanel header="Sem Aplicação">
                                <div class="content-selection implementation">
                                    <div class="grid-style">
                                        <GridSemAplicacao
                                            :records="noApplication"
                                            @onSelectCargo="onSelectCargo"
                                            @onIncluir="onIncluirSemAplicacao"
                                        ></GridSemAplicacao>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel header="Atualização">
                                <div class="grid-style">
                                    <GridAtualizacao :records="update" @onEditar="onEditarAtualizacao"></GridAtualizacao>
                                </div>
                            </TabPanel>
                            <TabPanel header="Em Andamento">
                                <div class="grid-style">
                                    <GridEmAndamento :records="progress" @onEditar="onEditarAtualizacao"></GridEmAndamento>
                                </div>
                            </TabPanel>
                            <TabPanel header="Aguardando Vigência">
                                <div class="grid-style">
                                    <GridAguardandoVigencia :records="validity"></GridAguardandoVigencia>
                                </div>
                            </TabPanel>
                            <TabPanel header="Concluídos">
                                <div class="grid-style">
                                    <GridConcluidas :records="concluded" @onNovaVersao="onNovaVersaoConcluidas"></GridConcluidas>
                                </div>
                            </TabPanel>
                        </TabView>

                        <Dialog
                            class="card"
                            v-model:visible="displayApplications"
                            :style="{ width: '800px' }"
                            header="Inventário de Risco"
                            :modal="true"
                        >
                            <div class="m-2 col-12 lg:col-4 lg:mb-0" :style="{ display: this.lastFrame ? 'flex' : 'none' }">
                                <h6>Data de Vigência</h6>
                                <Calendar class="p-component sm bt-sm" v-model="this.effectiveDate" />
                            </div>
                            <div class="field-sm flex align-items-center justify-content-center">
                                <Accordion :multiple="true" :style="{ width: '100%', flexDirection: 'column' }">
                                    <AccordionTab header="Aplicação de Risco" :disabled="this.lastFrame">
                                        <div :style="{ display: this.lastFrame ? 'none' : 'flex', flexDirection: 'column' }">
                                            <div class="grid formgrid mb-3">
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <span class="mb-5 bt-sm"><b>Fator de Risco:</b></span
                                                    ><br />
                                                    <MultiSelect
                                                        class="p-multiselect-item"
                                                        v-model="selectedGroupRisk"
                                                        :options="groupRisk"
                                                        optionLabel="agentName"
                                                    />
                                                </div>
                                            </div>
                                            <Toast />
                                            <div class="grid-style">
                                                <DataTable
                                                    :value="selectedGroupRisk"
                                                    v-model:expandedRows="expandedRows"
                                                    dataKey="name"
                                                    responsiveLayout="scroll"
                                                >
                                                    <Column class="sm" :expander="true" headerStyle="width: 3rem" />
                                                    <Column :sortable="true" field="name" header="Fator de Risco" />
                                                    <template #expansion="slotProps">
                                                        <div class="p-panel p-component">
                                                            <div class="p-panel-header">
                                                                <span class="p-panel-title">Caracteristica do Risco</span>
                                                            </div>
                                                            <div class="card">
                                                                <div class="grid formgrid">
                                                                    <div class="col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Data Início</h6>
                                                                        <Calendar class="p-component bt-sm" v-model="slotProps.data.dateStart" />
                                                                    </div>
                                                                    <div class="col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Data Fim</h6>
                                                                        <Calendar class="p-component sm bt-sm" v-model="slotProps.data.dateEnd" />
                                                                    </div>
                                                                    <div class="col-12lg:col-4 lg:mb-0">
                                                                        <h6>Tipo de Exposição</h6>
                                                                        <Dropdown
                                                                            class="p-component p-dropdown bt-sm"
                                                                            v-model="slotProps.data.typeExposure"
                                                                            :options="groupTipoRisk"
                                                                            optionLabel="type"
                                                                        />
                                                                    </div>
                                                                    <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Probabilidade</h6>
                                                                        <Dropdown
                                                                            class="p-component p-dropdown bt-sm"
                                                                            v-model="slotProps.data.probability"
                                                                            :options="randomIds"
                                                                            optionLabel="id"
                                                                        />
                                                                    </div>
                                                                    <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Gravidade</h6>
                                                                        <Dropdown
                                                                            class="p-component p-dropdown bt-sm"
                                                                            v-model="slotProps.data.gravity"
                                                                            :options="randomIds"
                                                                            optionLabel="id"
                                                                        />
                                                                    </div>
                                                                    <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Nível</h6>
                                                                        <Dropdown
                                                                            class="p-component p-dropdown bt-sm"
                                                                            v-model="slotProps.data.levelScratch"
                                                                            :options="randomIds"
                                                                            optionLabel="id"
                                                                        />
                                                                    </div>
                                                                    <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Fonte Geradora:</h6>
                                                                        <MultiSelect
                                                                            class="p-component p-dropdown"
                                                                            v-model="slotProps.data.selectedGeneratingSource"
                                                                            @change="changeSource($event, slotProps.data.id)"
                                                                            :options="generatingSource"
                                                                            optionLabel="name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="mt-2">
                                                                    <hr />
                                                                    <div class="grid-style">
                                                                        <DataTable
                                                                            style="width: 100%"
                                                                            :value="
                                                                                selectedGeneratingSource.filter((v) => v.idRisk === slotProps.data.id)
                                                                            "
                                                                            v-model:expandedRows="expandedRows"
                                                                            responsiveLayout="scroll"
                                                                        >
                                                                            <Column :sortable="true" :expander="true" headerStyle="width: 3rem" />
                                                                            <Column :sortable="true" field="name" header="Fonte Geradora"></Column>
                                                                            <template #expansion="slotPropsSource">
                                                                                <div class="p-panel p-component">
                                                                                    <div class="p-panel-header">
                                                                                        <span class="p-panel-title"
                                                                                            >Característica Fonte Geradora</span
                                                                                        >
                                                                                    </div>
                                                                                    <div class="card">
                                                                                        <div class="grid formgrid">
                                                                                            <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                                                <h6>Trajetória</h6>
                                                                                                <InputText
                                                                                                    class="p-component bt-sm"
                                                                                                    type="input"
                                                                                                    v-model="slotPropsSource.data.trajectory"
                                                                                                />
                                                                                            </div>
                                                                                            <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                                                <h6>Possíveis Danos</h6>
                                                                                                <InputText
                                                                                                    class="p-component bt-sm"
                                                                                                    type="input"
                                                                                                    v-model="slotPropsSource.data.possibleDamage"
                                                                                                />
                                                                                            </div>
                                                                                            <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                                                <h6>Intensidade</h6>
                                                                                                <Dropdown
                                                                                                    class="p-component p-dropdown bt-sm"
                                                                                                    v-model="slotPropsSource.data.intensity"
                                                                                                    :options="randomIds"
                                                                                                    optionLabel="id"
                                                                                                />
                                                                                            </div>
                                                                                            <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                                                <h6>Limite de Tolerancia</h6>
                                                                                                <Dropdown
                                                                                                    class="p-component p-dropdown bt-sm"
                                                                                                    v-model="slotPropsSource.data.toleranceLimit"
                                                                                                    :options="randomIds"
                                                                                                    optionLabel="id"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                        </DataTable>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="Aplicação de Exame" :disabled="this.lastFrame">
                                        <div :style="{ display: this.lastFrame ? 'none' : 'flex', flexDirection: 'column' }">
                                            <span class="m-1 bt-sm">Exames:</span>
                                            <MultiSelect
                                                class="p-multiselect-item"
                                                v-model="selectedGroupExame"
                                                :options="groupExame"
                                                optionLabel="name"
                                            ></MultiSelect>
                                            <hr />
                                            <Toast />
                                            <div class="grid-style">
                                                <DataTable :value="selectedGroupExame" v-model:expandedRows="expandedRows" responsiveLayout="scroll">
                                                    <Column :sortable="true" class="sm" :expander="true" headerStyle="width: 3rem" />
                                                    <Column :sortable="true" field="name" header="Exame"></Column>
                                                    <Column :sortable="true" field="risk.name" header="Risco"></Column>
                                                    <template #expansion="slotProps">
                                                        <div class="p-panel p-component">
                                                            <div class="p-panel-header">
                                                                <span class="p-panel-title">Classificação do Exame</span>
                                                            </div>
                                                            <div class="card">
                                                                <div class="grid formgrid">
                                                                    <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Tipo de Exames</h6>
                                                                        <Dropdown
                                                                            class="p-component bt-sm"
                                                                            v-model="slotProps.data.typeExams"
                                                                            :options="groupTypeExam"
                                                                            optionLabel="label"
                                                                        />
                                                                    </div>
                                                                    <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Periodicidade</h6>
                                                                        <Dropdown
                                                                            class="p-component bt-sm"
                                                                            v-model="slotProps.data.frequency"
                                                                            :options="groupPeriod"
                                                                            optionLabel="type"
                                                                        />
                                                                    </div>
                                                                    <div class="col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Quando deve fazer</h6>
                                                                        <Calendar class="p-component bt-sm" v-model="slotProps.data.date" />
                                                                    </div>
                                                                    <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                                        <div class="field-checkbox">
                                                                            <Checkbox
                                                                                id="binary"
                                                                                v-model="slotProps.data.foodHandling"
                                                                                :binary="true"
                                                                            />
                                                                            <label for="binary">Manipulação de alimentos</label>
                                                                        </div>
                                                                        <div class="field-checkbox">
                                                                            <Checkbox id="binary" v-model="slotProps.data.sheltered" :binary="true" />
                                                                            <label for="binary">Brigadista</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>Parecer Médico</h6>
                                                                        <Dropdown
                                                                            class="p-component bt-sm"
                                                                            v-model="slotProps.data.medicalSeem"
                                                                            :options="groupSeemMedical"
                                                                            optionLabel="type"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="Parecer Técnico" :disabled="this.lastFrame">
                                        <div :style="{ display: this.lastFrame ? 'none' : 'flex', flexDirection: 'column' }">
                                            <div class="p-panel p-component">
                                                <div class="p-panel-header">
                                                    <span class="p-panel-title">Classificação da Medidas</span>
                                                </div>
                                                <div class="card">
                                                    <div class="grid formgrid">
                                                        <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                            <div class="field-checkbox">
                                                                <Checkbox id="unsanitary" v-model="selectedSeemMedical.unsanitary" :binary="true" />
                                                                <label for="unsanitary">Insalubridade</label>
                                                            </div>
                                                            <h6>Motivo</h6>
                                                            <InputText
                                                                class="p-component bt-sm"
                                                                type="input"
                                                                v-model="selectedSeemMedical.reasonUnsanitary"
                                                            />
                                                        </div>
                                                        <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                            <div class="field-checkbox">
                                                                <Checkbox
                                                                    id="dangerousness"
                                                                    v-model="selectedSeemMedical.dangerousness"
                                                                    :binary="true"
                                                                />
                                                                <label for="dangerousness">Periculosidade</label>
                                                            </div>
                                                            <h6>Motivo</h6>
                                                            <InputText
                                                                class="p-component bt-sm"
                                                                type="input"
                                                                v-model="selectedSeemMedical.reasonDangerousness"
                                                            />
                                                        </div>

                                                        <div class="mt-2 col-12 lg:col-4 lg:mb-0">
                                                            <div class="field-checkbox">
                                                                <Checkbox
                                                                    id="specialRetirement"
                                                                    v-model="selectedSeemMedical.specialRetirement"
                                                                    :binary="true"
                                                                />
                                                                <label for="specialRetirement">Aposentadoria Especial</label>
                                                            </div>
                                                            <h6>Motivo</h6>
                                                            <InputText
                                                                class="p-component bt-sm"
                                                                type="input"
                                                                v-model="selectedSeemMedical.reasonSpecialRetirement"
                                                            />
                                                        </div>
                                                        <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                            <h6>Conclusão</h6>
                                                            <Textarea
                                                                class="p-component"
                                                                v-model="selectedSeemMedical.conclusion"
                                                                rows="5"
                                                                cols="30"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="Medidas de Controle" :disabled="this.lastFrame">
                                        <div :style="{ display: this.lastFrame ? 'none' : 'flex', flexDirection: 'column' }">
                                            <span class="m-1 bt-sm">Medidas/Ações:</span>
                                            <MultiSelect
                                                class="p-multiselect-item"
                                                v-model="selectedGroupMeasurements"
                                                :options="groupMedidas"
                                                optionLabel="name"
                                            />
                                            <hr />
                                            <Toast />
                                            <div class="grid-style">
                                                <DataTable
                                                    :value="selectedGroupMeasurements"
                                                    v-model:expandedRows="expandedRows"
                                                    responsiveLayout="scroll"
                                                >
                                                    <Column :sortable="true" class="sm" :expander="true" headerStyle="width: 3rem" />
                                                    <Column :sortable="true" field="name" header="Medida"></Column>
                                                    <Column :sortable="true" field="risk.name" header="Risco"></Column>
                                                    <template #expansion="slotProps">
                                                        <div class="p-panel p-component">
                                                            <div class="p-panel-header">
                                                                <span class="p-panel-title">Classificação da Medidas</span>
                                                            </div>
                                                            <div class="card">
                                                                <div class="grid formgrid">
                                                                    <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>EPI</h6>
                                                                        <Dropdown
                                                                            class="p-component bt-sm"
                                                                            v-model="slotProps.data.epi"
                                                                            :options="groupTipoEpi"
                                                                            optionLabel="type"
                                                                        />
                                                                    </div>
                                                                    <div class="m-2 col-12 lg:col-4 lg:mb-0">
                                                                        <h6>EPC</h6>
                                                                        <Dropdown
                                                                            class="p-component bt-sm"
                                                                            v-model="slotProps.data.epc"
                                                                            :options="groupTipoEpc"
                                                                            optionLabel="type"
                                                                        />
                                                                    </div>
                                                                    <div class="m-2 col-12 lg:col-12 lg:mb-0">
                                                                        <h6>Medida administrativa</h6>
                                                                        <Textarea v-model="slotProps.data.administrativeMeasure" rows="5" cols="30" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                            <div :style="{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }">
                                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="cleanForms" />
                                <div>
                                    <Button
                                        label="Concluir"
                                        icon="pi pi-check"
                                        class="p-button-outlined"
                                        @click="concludedApplication"
                                        :style="{ marginRight: '10px' }"
                                    />
                                    <Button
                                        label="Gravar"
                                        icon="pi pi-check"
                                        class="p-button-outlined p-button-success"
                                        @click="saveApplication"
                                        :disabled="this.lastFrame"
                                    />
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SecurityService from '@/services/SecurityService';
import dayjs from 'dayjs';
import SesmtService from '@/services/SesmtService';
import GridSemAplicacao from './components/GridSemAplicacao.vue';
import GridAtualizacao from './components/GridAtualizacao.vue';
import GridEmAndamento from './components/GridEmAndamento.vue';
import GridAguardandoVigencia from './components/GridAguardandoVigencia.vue';
import GridConcluidas from './components/GridConcluidas.vue';
import AppInfoManual from '../../../components/AppInfoManual.vue';

export default {
    components: { GridSemAplicacao, GridAtualizacao, GridEmAndamento, GridAguardandoVigencia, GridConcluidas, AppInfoManual },
    data() {
        return {
            tooltip: 'Gerenciamento de Riscos',
            subtitle: 'Inventário de Riscos',
            displayCharge: false,
            displayEmployees: false,
            listCharge: [],
            listHierarchy: [],
            formCharge: {},
            selectedCargo: null,
            selectedGroupMeasurements: [],
            form: {},
            active: 0,
            groupMedidas: [],
            groupTipoRisk: [{ type: 'qualitativo' }, { type: 'quantitativo' }],
            groupTipoEpi: [{ type: 'Protetor Auricular' }, { type: 'Capacete' }, { type: 'Luva' }],
            groupTipoEpc: [{ type: 'Guarda Corpo' }, { type: 'Faixa de Sinalização' }, { type: 'Grade de Proteção' }],
            groupSeemMedical: [
                { type: 'Trabalho em Altura' },
                { type: 'Trabalho em Espaço Confinado' },
                { type: 'Trabalho com Eletricidade' },
                { type: 'Trabalho em Operaçao de Máquinas e Equipamentos' },
                { type: 'Manipulação de Alimentos' }
            ],
            groupTypeExam: [
                { label: 'Admissional' },
                { label: 'Periódico' },
                { label: 'Retorno ao Trabalho' },
                { label: 'Demissional' },
                { label: 'Mudança de Riscos Ocupacionais' },
                { label: 'Monitoração Pontual' },
                { label: 'Acidente' },
                { label: 'Terceiros' },
                { label: 'Consulta' },
                { label: 'Licença Médica' },
                { label: 'Retorno Consulta' },
                { label: 'Enfermagem' }
            ],
            groupPeriod: [{ type: '6' }, { type: '12' }, { type: '24' }, { type: '36' }, { type: '48' }],
            selectedGroupExame: [],
            groupExame: [],
            selectedGroupRisk: [],
            groupRisk: [],
            generatingSource: [],
            selectedGeneratingSource: [],
            selectedSeemMedical: {},
            randomIds: [
                { id: 1, value: 1 },
                { id: 2, value: 2 },
                { id: 3, value: 3 },
                { id: 4, value: 4 }
            ],
            record: null,
            noApplication: [],
            progress: [],
            validity: [],
            concluded: [],
            update: [],
            listEmployees: [],

            optionsAction: [{ label: 'Edição em massa', value: 3 }],
            expandedRows: [],
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            displayApplications: false,
            lastFrame: false,
            effectiveDate: '',
            displayBasic: false,
            filter: [],
            actionRecord: null
        };
    },
    mounted() {
        this.$inventory = new SecurityService('/risk-inventory');
        this.$inventoryHierarchy = new SecurityService('/inventory/hierarchy');
        this.$inventoryEmployee = new SecurityService('/inventory/employee');
        this.$risk = new SecurityService('/risks');
        this.$generatingSource = new SecurityService('/fontes-geradoras');
        this.$exam = new SesmtService('/procedures');
        this.$measurements = new SecurityService('/measurements');
        this.$applicationScratch = new SecurityService('/application/scratch');
        this.$applicationScratchGeneratingSource = new SecurityService('/application/generating-source');
        this.$applicationExam = new SecurityService('/application/exam');
        this.$applicationSeem = new SecurityService('/application/seem');
        this.$applicationMeasures = new SecurityService('/application/measures');
        this.load();
    },
    methods: {
        onSelectCargo(newRecord) {
            this.selectedCargo = newRecord;
        },
        onEditarAtualizacao(record) {
            this.actionRecord = record;
            this.getAllRisks();
            this.getAllFinalSources();
            this.getAllExams();
            this.getAllSeemMedical();
            this.getAllMeasures();
        },
        onIncluirSemAplicacao(record) {
            this.cleanForms();
            this.actionRecord = record;

            this.groupExame.forEach((group) => {
                group.typeExams = null;
                group.frequency = null;
                group.date = null;
                group.foodHandling = null;
                group.sheltered = null;
                group.medicalSeem = null;
            });

            this.groupRisk.forEach((risk) => {
                risk.dateStart = null;
                risk.dateEnd = null;
                risk.probability = null;
                risk.gravity = null;
                risk.levelScratch = null;
                risk.selectedGeneratingSource = null;
            });

            this.aplicarRisco(this.actionRecord);
        },
        async onNovaVersaoConcluidas(record) {
            this.actionRecord = record;
            try {
                this.$applicationGoAtt = new SecurityService(`/risk-inventory/${this.actionRecord.id}/employee`);
                await this.$applicationGoAtt.put();
                this.$toast.add({ severity: 'success', summary: 'Item modificado de concluído para atualização!', life: 3000 });
                this.load();
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao modificar o item concluído para atualização!', life: 3000 });
            }
        },
        openBasic() {
            this.displayBasic = true;
        },
        cleanForms() {
            this.displayApplications = false;
            this.selectedGroupRisk = [];
            this.selectedGeneratingSource = [];
            this.selectedGroupExame = [];
            this.selectedGroupMeasurements = [];
            this.selectedSeemMedical = {};
            this.record = null;
            this.lastFrame = false;
            this.effectiveDate = '';
        },
        changeSource(event, idRisk) {
            const ExistIdRisk = this.selectedGeneratingSource?.filter((v) => v.idRisk === idRisk);
            if (ExistIdRisk.length > 0) {
                const removeId = this.selectedGeneratingSource?.filter((v) => v.idRisk !== idRisk);
                const AddIdRisk = event.value?.map((v) => {
                    return {
                        ...v,
                        idRisk
                    };
                });
                this.selectedGeneratingSource = [...removeId, ...AddIdRisk];
            } else {
                const AddIdRisk = event.value?.map((v) => {
                    return {
                        ...v,
                        idRisk
                    };
                });
                this.selectedGeneratingSource = [...this.selectedGeneratingSource, ...AddIdRisk];
            }
        },
        async getAllRisks() {
            const getRisks = this.actionRecord.scratchs.map((v) => {
                return {
                    ...v.risk,
                    probability: v?.probability ? { id: v?.probability, value: v?.probability } : null,
                    gravity: v?.gravity ? { id: v?.gravity, value: v?.gravity } : null,
                    levelScratch: v?.levelScratch ? { id: v?.levelScratch, value: v?.levelScratch } : null,
                    typeExposure: v?.typeExposure ? { type: v?.typeExposure } : null,
                    dateStart: v?.dateStart ? new Date(v?.dateStart) : null,
                    dateEnd: v?.dateEnd ? new Date(v?.dateEnd) : null,
                    idUpdate: v?.id || null,
                    selectedGeneratingSource:
                        v?.generatingSources?.length > 0
                            ? v?.generatingSources?.map((e) => {
                                  return {
                                      ...e.generatingSource
                                  };
                              })
                            : null
                };
            });
            this.selectedGroupRisk = getRisks;
            const getAllIdsRisks = getRisks?.map((v) => v.id);
            const listRisks = await this.$risk.findAll();
            const moreRisks = listRisks?.data?.items.filter((v) => !getAllIdsRisks?.includes(v.id));
            this.groupRisk = [...moreRisks, ...getRisks];
        },
        getAllFinalSources() {
            const getFinalSources = [];
            this.actionRecord.scratchs.map((v) => {
                const getSplitSources = v.generatingSources.map((e) => {
                    return {
                        ...e.generatingSource,
                        intensity: e?.intensity ? { id: e?.intensity, value: e?.intensity } : null,
                        toleranceLimit: e?.toleranceLimit ? { id: e?.toleranceLimit, value: e?.toleranceLimit } : null,
                        trajectory: e?.trajectory ? e?.trajectory : null,
                        possibleDamage: e?.possibleDamage ? e?.possibleDamage : null,
                        idRisk: e?.risk?.id
                    };
                });
                getFinalSources.push(...getSplitSources);
                return getSplitSources;
            });
            this.selectedGeneratingSource = getFinalSources;
        },
        async getAllExams() {
            const getExams = this.actionRecord.exams.map((v) => {
                return {
                    ...v.exam,
                    typeExams: v?.typeExams ? { label: v?.typeExams } : null,
                    frequency: v?.frequency ? { type: v.frequency } : null,
                    date: v?.date ? new Date(v?.date) : null,
                    foodHandling: v?.foodHandling || false,
                    sheltered: v?.sheltered || false,
                    medicalSeem: { type: v?.medicalSeem } || null,
                    idUpdate: v?.id || null
                };
            });

            const getAllIdsExams = getExams?.map((v) => v.id);
            const listExam = await this.$exam.findAll({});
            const moreExam = listExam?.data?.items?.filter((v) => !getAllIdsExams?.includes(v.id));
            this.groupExame = [...moreExam, ...getExams];
            this.selectedGroupExame = getExams;
        },
        getAllSeemMedical() {
            this.selectedSeemMedical = {
                unsanitary: this.actionRecord?.seems?.[0]?.unsanitary || false,
                reasonUnsanitary: this.actionRecord?.seems?.[0]?.reasonUnsanitary || '',
                dangerousness: this.actionRecord?.seems?.[0]?.dangerousness || false,
                reasonDangerousness: this.actionRecord?.seems?.[0]?.reasonDangerousness || '',
                specialRetirement: this.actionRecord?.seems?.[0]?.specialRetirement || false,
                reasonSpecialRetirement: this.actionRecord?.seems?.[0]?.reasonSpecialRetirement || '',
                conclusion: this.actionRecord?.seems?.[0]?.conclusion || '',
                idUpdate: this.actionRecord?.seems?.[0]?.id || null
            };
        },
        async getAllMeasures() {
            const getMeasures = this.actionRecord.measures.map((v) => {
                return {
                    ...v.measurement,
                    epi: v?.epi ? { type: v.epi } : null,
                    epc: v?.epc ? { type: v.epc } : null,
                    administrativeMeasure: v?.administrativeMeasure || '',
                    idUpdate: v?.id
                };
            });

            const getAllIdsMeasures = getMeasures?.map((v) => v.id);
            const listMeasurements = await this.$measurements.findAll();
            const moreMeasurements = listMeasurements?.data?.items?.filter((v) => !getAllIdsMeasures?.includes(v.id));
            this.groupMedidas = [...moreMeasurements, ...getMeasures];
            this.selectedGroupMeasurements = getMeasures;

            this.aplicarRisco(this.actionRecord);
        },
        async actionDropDawn() {
            const statusTabs = {
                SEM_APLICACAO: 0,
                ATUALIZAÇÃO: 1,
                EM_ANDAMENTO: 2,
                AGUARDANDO_VIGENCIA: 3,
                CONCLUIDO: 4
            };
            if (this.active === statusTabs.SEM_APLICACAO) {
                this.aplicarRisco(this.actionRecord);
            }
            if ([statusTabs.ATUALIZAÇÃO, statusTabs.EM_ANDAMENTO].includes(this.active)) {
                this.getAllRisks();
                this.getAllFinalSources();
                this.getAllExams();
                this.getAllSeemMedical();
                this.getAllMeasures();
            }
            if (this.active === statusTabs.CONCLUIDO) {
                try {
                    this.$applicationGoAtt = new SecurityService(`/risk-inventory/${this.actionRecord.id}/employee`);
                    await this.$applicationGoAtt.put();
                    this.$toast.add({ severity: 'success', summary: 'Item modificado de concluído para atualização!', life: 3000 });
                    this.load();
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Problemas ao modificar o item concluído para atualização!', life: 3000 });
                }
            }
        },
        formatDate(dateString) {
            const formatAddHoursDates = dayjs(new Date(dateString)).add(3, 'hour');
            const formatDates = dayjs(formatAddHoursDates).format('YYYY-MM-DD');
            return formatDates;
        },
        async linkRiskToItem() {
            const allRequestsRisks = await this.selectedGroupRisk.map((v) => {
                return {
                    dateStart: v?.dateStart ? this.formatDate(v?.dateStart) || '' : '',
                    dateEnd: v?.dateEnd ? this.formatDate(v?.dateEnd) || '' : '',
                    typeExposure: v?.typeExposure?.type || '',
                    probability: v?.probability?.id || '',
                    gravity: v?.gravity?.id || '',
                    levelScratch: v?.levelScratch?.id || '',
                    idRisk: v?.id,
                    id: v?.idUpdate || null
                };
            });
            const body = {
                idReference: this.active ? this?.record?.id : null,
                idHierarchy: !this.active ? this?.record?.id : null,
                type: this?.record?.type,
                applicationRisks: allRequestsRisks
            };
            const stringBody = JSON.stringify(body);
            await this.$applicationScratch.save(stringBody);
        },
        async linkGeneratingSourceToRisk() {
            if (this.selectedGeneratingSource.length > 0) {
                const formatGeneratingSource = this.selectedGeneratingSource.map((v) => {
                    return {
                        trajectory: v?.trajectory,
                        intensity: v?.intensity?.id,
                        toleranceLimit: v?.toleranceLimit?.id,
                        possibleDamage: v?.possibleDamage,
                        idRisk: v?.idRisk,
                        idGeneratingSource: v?.id
                    };
                });
                const allIdsRisks = this.selectedGroupRisk.map((v) => v?.id);
                const splitGeneratingSource = allIdsRisks.map((e) => {
                    if (formatGeneratingSource.filter((v) => v.idRisk === e)) {
                        return {
                            allSourcesForRisks: formatGeneratingSource.filter((v) => v.idRisk === e)
                        };
                    }
                });
                const allRequestsGeneratingSource = splitGeneratingSource.map(async (v) => {
                    if (v.allSourcesForRisks?.length > 0) {
                        const body = {
                            generatingSources: v.allSourcesForRisks,
                            idReference: this.active ? this?.record?.id : null,
                            idHierarchy: !this.active ? this?.record?.id : null,
                            type: this?.record?.type,
                            idRisk: v.allSourcesForRisks[0].idRisk
                        };
                        const stringBody = JSON.stringify(body);
                        await this.$applicationScratchGeneratingSource.save(stringBody);
                    }
                });
                await Promise.all(allRequestsGeneratingSource);
            }
        },
        async createApplicationRisk(type) {
            try {
                this.linkRiskToItem();
                this.linkGeneratingSourceToRisk();
                this.$toast.add({ severity: 'success', summary: 'Aplicação de Risco gravado com sucesso!', life: 3000 });
                if (typeof type !== 'boolean') {
                    this.displayApplications = false;
                    this.load();
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao gravar Aplicação de Risco!', life: 3000 });
            }
        },
        linkExamToItem() {
            const allRequestsExam = this.selectedGroupExame.map((v) => {
                return {
                    typeExams: v?.typeExams?.label || '',
                    frequency: v?.frequency ? v.frequency?.type : '',
                    date: v?.date ? this.formatDate(v?.date) || '' : '',
                    foodHandling: v?.foodHandling || false,
                    sheltered: v?.sheltered || false,
                    medicalSeem: v?.medicalSeem?.type || '',
                    idExam: v?.id,
                    idRisk: v?.risk?.id,
                    id: v?.idUpdate || null
                };
            });
            return allRequestsExam;
        },
        async createApplicationExams(type) {
            try {
                const allRequestsExam = this.linkExamToItem();
                const body = {
                    idReference: this.active ? this?.record?.id : null,
                    idHierarchy: !this.active ? this?.record?.id : null,
                    type: this?.record?.type,
                    applicationExams: allRequestsExam
                };
                const stringBody = JSON.stringify(body);
                await this.$applicationExam.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Aplicação de Exame gravado com sucesso!', life: 3000 });
                if (typeof type !== 'boolean') {
                    this.displayApplications = false;
                    this.load();
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao gravar Aplicação de Exame!', life: 3000 });
            }
        },
        async createSeemMedical() {
            try {
                const body = {
                    unsanitary: this.selectedSeemMedical?.unsanitary || false,
                    reasonUnsanitary: this.selectedSeemMedical?.reasonUnsanitary || '',
                    dangerousness: this.selectedSeemMedical?.dangerousness || false,
                    reasonDangerousness: this.selectedSeemMedical?.reasonDangerousness || '',
                    specialRetirement: this.selectedSeemMedical?.specialRetirement || false,
                    reasonSpecialRetirement: this.selectedSeemMedical?.reasonSpecialRetirement || '',
                    conclusion: this.selectedSeemMedical?.conclusion || '',
                    idReference: this.active ? this?.record?.id : null,
                    idHierarchy: !this.active ? this?.record?.id : null,
                    type: this?.record?.type
                };
                const stringBody = JSON.stringify(body);
                await this.$applicationSeem.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Parecer Técnico gravado com sucesso!', life: 3000 });
                if (typeof type !== 'boolean') {
                    this.displayApplications = false;
                    this.load();
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao gravar Parecer Técnico!', life: 3000 });
            }
        },
        linkMeasurements() {
            const allRequestsMeasurements = this.selectedGroupMeasurements.map((v) => {
                return {
                    epi: v?.epi ? v.epi?.type : '',
                    epc: v?.epc ? v.epc?.type : '',
                    administrativeMeasure: v?.administrativeMeasure || '',
                    idMeasurement: v?.id,
                    idRisk: v?.risk?.id,
                    id: v?.idUpdate || null
                };
            });
            return allRequestsMeasurements;
        },
        async createGroupMeasurements(type) {
            try {
                const allRequestsMeasurements = this.linkMeasurements();
                const body = {
                    idReference: this.active ? this?.record?.id : null,
                    idHierarchy: !this.active ? this?.record?.id : null,
                    type: this?.record?.type,
                    applicationMeasures: allRequestsMeasurements
                };
                const stringBody = JSON.stringify(body);
                await this.$applicationMeasures.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Medidas de controle gravado com sucesso!', life: 3000 });
                if (typeof type !== 'boolean') {
                    this.displayApplications = false;
                    this.load();
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao gravar Medidas de controle!', life: 3000 });
            }
        },
        async saveApplication(type) {
            if (this.selectedGroupRisk.length > 0) {
                this.createApplicationRisk(type);
            }
            if (this.selectedGroupExame?.length > 0) {
                this.createApplicationExams(type);
            }
            if (Object.values(this.selectedSeemMedical).filter((v) => v).length > 0) {
                this.createSeemMedical(type);
            }
            if (this.selectedGroupMeasurements.length > 0) {
                this.createGroupMeasurements(type);
            }
        },
        async actionConcludedApplication() {
            try {
                this.$applicationFinished = new SecurityService(`/risk-inventory/finished/${this.record.id}/employee`);
                const body = {
                    effectiveDate: this.formatDate(this.effectiveDate)
                };
                const stringBody = JSON.stringify(body);
                await this.$applicationFinished.patch(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Item concluído com sucesso!', life: 3000 });
                this.displayApplications = false;
                this.load();
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao concluir item!', life: 3000 });
            }
        },
        async openSelectedDateVencimentoFromConcluded() {
            const verifyGroupRisk = !!this.selectedGroupRisk?.filter((v) => {
                if (!v.dateStart || !v.dateEnd || !v.typeExposure.type || !v.probability.id || !v.gravity.id || !v.levelScratch.id) {
                    return v;
                }
            })?.length;

            const verifyGeneratingSource = !!this.selectedGeneratingSource?.filter((v) => {
                if (!v.trajectory || !v.possibleDamage || !v.intensity.id || !v.toleranceLimit.id) {
                    return v;
                }
            })?.length;

            const verifyGroupExame = !!this.selectedGroupExame?.filter((v) => {
                if (!v.typeExams.label || !v.frequency.type || !v.date || !v.medicalSeem.type) {
                    return v;
                }
            })?.length;

            const verifyGroupMeasurements = !!this.selectedGroupMeasurements?.filter((v) => {
                if (!v.epi.type || !v.epc.type || !v.administrativeMeasure) {
                    return v;
                }
            })?.length;

            const totalTextInputs = 4;
            const verifySeemMedical =
                Object.values(this.selectedSeemMedical).filter((v) => {
                    if (typeof v === 'string' && v.length > 0) {
                        return v;
                    }
                }).length !== totalTextInputs;
            if (!verifyGroupRisk && !verifyGeneratingSource && !verifyGroupExame && !verifyGroupMeasurements && !verifySeemMedical) {
                await this.saveApplication(true);
                this.lastFrame = true;
            } else {
                this.$toast.add({ severity: 'error', summary: 'Preencha todos os campos para prosseguir!', life: 3000 });
            }
        },
        async concludedApplication() {
            if (this.lastFrame) {
                this.actionConcludedApplication();
            } else {
                this.openSelectedDateVencimentoFromConcluded();
            }
        },
        async createNewCharge() {
            try {
                this.submitted = true;
                const body = {
                    idPosition: this.formCharge?.charge?.[0]?.id,
                    idHierarchy: this.formCharge?.hierarchy?.[0]?.id
                };
                const stringBody = JSON.stringify(body);
                await this.$inventoryHierarchy.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Item salvo com sucesso!', life: 3000 });
                this.submitted = false;
                this.displayCharge = false;
                this.formCharge = {};
                this.load();
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o item!', life: 3000 });
            }
        },
        async createNewEmployees() {
            try {
                this.submitted = true;
                const body = {
                    idEmployees: this.formCharge?.employees?.map((v) => v.id) || []
                };
                const stringBody = JSON.stringify(body);
                await this.$inventoryEmployee.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Item salvo com sucesso!', life: 3000 });
                this.submitted = false;
                this.displayEmployees = false;
                this.formCharge = {};
                this.load();
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o item!', life: 3000 });
            }
        },
        async load() {
            const { data } = await this.$inventory.findAll();
            this.noApplication = data?.noApplication;
            this.update = data?.update;
            this.progress = data?.progress;
            this.validity = data?.validity;
            this.concluded = data?.concluded;
            const listGeneratingSource = await this.$generatingSource.findAll();
            this.generatingSource = listGeneratingSource?.data?.items;
            const listRisks = await this.$risk.findAll();
            this.groupRisk = listRisks?.data?.items;
            const listExam = await this.$exam.findAll({});
            this.groupExame = listExam?.data?.items;
            const listMeasurements = await this.$measurements.findAll();
            this.groupMedidas = listMeasurements?.data?.items;
        },
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },

        aplicarRisco(record) {
            this.record = record;
            this.displayApplications = true;
        },

        openModal(event) {
            const idDropdownActionSelected = event.value.value;

            const idDropdown = {
                SELECIONAR_FUNCIONARIO: 1,
                NOVO_CARGO: 2,
                EDICAO_EM_MASSA: 3
            };

            if (idDropdownActionSelected === idDropdown.SELECIONAR_FUNCIONARIO) {
                return (this.displayEmployees = true);
            }
            if (idDropdownActionSelected === idDropdown.NOVO_CARGO) {
                return (this.displayCharge = true);
            }
            if (idDropdownActionSelected === idDropdown.EDICAO_EM_MASSA) {
                if (this.selectedCargo?.length >= 2) {
                    return this.aplicarRisco();
                } else {
                    alert('É necessário selecionar pelo menos dois itens da lista.');
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.bt-sm {
    height: 2.5em;
}
.p-panel-header {
    padding: 0.5rem;
}
.p-sortable-column {
    padding: 0.5rem;
}
.p-tag {
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.3px;
}
.p-button {
    padding: 0.5rem;
}
.grid-style {
    border: 1px solid #dee2e6;
}
</style>
