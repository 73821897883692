<template lang="">
    <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px" class="p-datatable-sm">
        <template #empty> Nenhum registro encontrado. </template>
        <template #loading> Carregando registros. Aguarde ... </template>
        <Column selectionMode="multiple" headerStyle="width: 3em" />
        <Column :sortable="true" field="hierarchy.customerBranch.name" header="Unidade" />
        <Column :sortable="true" field="hierarchy.customerDepartment.name" header="Setor" />
        <Column :sortable="true" field="hierarchy.customerPosition.name" header="Cargo" />
        <Column bodyClass="text-right" headerStyle="width: 10rem;">
            <template #body="slotProps">
                <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
            </template>
        </Column>
    </DataTable>
    <Menu ref="menu" :model="actionItems" :popup="true" />
</template>
<script>
export default {
    props: {
        records: {
            type: Array,
            required: true
        }
    },
    emits: ['onEditar'],
    data() {
        return {
            actionItems: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => this.actionDropDawn()
                }
            ]
        };
    },
    methods: {
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        actionDropDawn() {
            this.$emit('onEditar', this.actionRecord);
        }
    }
};
</script>
