<template lang="">
    <DataTable
        dataKey="id"
        v-model:selection="selectedCargo"
        :value="records"
        :row-hover="true"
        responsiveLayout="scroll"
        breakpoint="640px"
        class="p-datatable-sm"
    >
        <template #empty> Nenhum registro encontrado. </template>
        <template #loading> Carregando registros. Aguarde ... </template>
        <Column selectionMode="multiple" headerStyle="width: 3em" />
        <Column :sortable="true" field="customerBranch.name" header="Unidade"></Column>
        <Column :sortable="true" field="customerDepartment.name" header="Setor"></Column>
        <Column :sortable="true" field="customerPosition.name" header="Cargo"></Column>
        <Column :sortable="true" header="Tipo">
            <template #body="slotProps">
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <text>{{ slotProps.data?.type === 'employee' ? 'Funcionário' : 'Hierarquia' }}</text>
                    <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-sm" @click="toggleMenu($event, slotProps.data)" />
                </div>
            </template>
        </Column>
    </DataTable>
    <Menu ref="menu" :model="actionItems" :popup="true" />
    <Paginator
        :rows="perPage"
        :totalRecords="total"
        :v-model:first="page"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
    />
</template>
<script>

export default {
    props: {
        records: {
            type: Array,
            required: true
        }
    },
    emits: ['onSelectCargo', 'onIncluir'],
    watch: {
        selectedCargo(newRecord) {
            this.$emit('onSelectCargo', newRecord);
        }
    },
    data() {
        return {
            selectedCargo: null,
            actionRecord: null,
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            displayApplications: false,
            actionItems: [
                {
                    label: 'Incluir',
                    icon: 'pi pi-pencil',
                    command: () => this.actionDropDawn()
                }
            ]
        };
    },
    methods: {
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        actionDropDawn() {
            this.$emit('onIncluir', this.actionRecord)
        }
    }
};
</script>
