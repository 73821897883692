<template lang="">
    <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="scroll" breakpoint="640px" class="p-datatable-sm">
        <template #empty> Nenhum registro encontrado. </template>
        <template #loading> Carregando registros. Aguarde ... </template>
        <Column selectionMode="multiple" headerStyle="width: 3em" />
        <Column :sortable="true" field="hierarchy.customerBranch.name" header="Unidade" />
        <Column :sortable="true" field="hierarchy.customerDepartment.name" header="Setor" />
        <Column :sortable="true" field="hierarchy.customerPosition.name" header="Cargo" />
    </DataTable>
</template>
<script>
export default {
    props: {
        records: {
            type: Array,
            required: true
        }
    }
};
</script>
